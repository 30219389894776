import { observable, action } from "mobx";

import { iconRender } from "~/core/utils";


import ObjectStore from "./objectStore";
import ConfigStore from "./configStore";
import UiStore from "./uiStore";
import AccountStore from "~/modules/account/stores/accountStore";
import UserStore from "~/modules/users/stores/userStore";
import RelationStore from "~/modules/relations/stores/relationStore";
import KindsStore from "~/modules/kindsAndAttrs/stores/kindsStore";
import WSStore from "~/modules/webSocket/stores/wsStore";
import WorkflowStore from "~/modules/workflow/stores/workflowStore";
import AboutStore from "~/modules/about/data/stores/AboutStore";

class RootStore {
  @observable
  userStore = null;
  @observable
  accountStore = null;
  @observable
  relationStore = null;
  @observable
  objectStore = null;
  @observable
  uiStore = null;
  @observable
  wsStore = null;
  @observable
  workflowStore = null;
  @observable
  aboutStore = null;
  @observable
  modes = new Map();

  constructor() {
    // запросы будут ходить не на сервисы, а будет использоваться localStorage
    this.useLocalStorage = false;

    // Стор пользователей
    this.uiStore = new UiStore(this);
    this.userStore = new UserStore(this);

    // учетная запись
    this.accountStore = new AccountStore(this);
    this.accountStore.init();

    // список объектов
    this.objectStore = new ObjectStore(this);

    this.relationStore = new RelationStore(this);
    this.configStore = new ConfigStore(this);
    this.kindsStore = new KindsStore(this);
    this.workflowStore = new WorkflowStore(this);
    this.wsStore = new WSStore(this);
    this.aboutStore = new AboutStore(this);

    this.iconString = this.iconString.bind(this);
  }

  iconString(item) {
    let userIconString = null;
    if (this.accountStore !== undefined) {
      userIconString = this.accountStore.getIconString(item);
    }
    if (userIconString) {
      return userIconString;
    }
    return iconRender(item, true);
  }

  @action
  enableMode(mode) {
    this.modes.set(mode, true);
  }

  @action
  disableMode(mode) {
    this.modes.delete(mode);
  }

  @action
  setErrorText(text, critical, status) {
    this.uiStore.setErrorText(text, critical, status);
  }
 
  onError(err, criticalOrStatus = false, stat = null) {
    let critical = false;
    let status = stat;

    // проверка второго параметра
    if (typeof criticalOrStatus === "number") {
      status = criticalOrStatus;
    } else {
      critical = criticalOrStatus;
    }
    let error = err;
    if (typeof err === "string") {
      error = {
        code:    undefined,
        message: err
      };
    }
    console.warn(err);
    this.setErrorText(error.message, critical, status);
  }

  /**
   * Деструктор хранилища
   */
  destroy() {
    try {
      if (this.uiStore && this.uiStore.destroy) {
        this.uiStore.destroy();
      }

      if (this.userStore && this.userStore.destroy) {
        this.userStore.destroy();
      }

      if (this.accountStore && this.accountStore.destroy) {
        this.accountStore.destroy();
      }

      if (this.objectStore && this.objectStore.destroy) {
        this.objectStore.destroy();
      }

      if (this.relationStore && this.relationStore.destroy) {
        this.relationStore.destroy();
      }

      if (this.configStore && this.configStore.destroy) {
        this.configStore.destroy();
      }

      if (this.kindsStore && this.kindsStore.destroy) {
        this.kindsStore.destroy();
      }
      if (this.workflowStore && this.workflowStore.destroy) {
        this.workflowStore.destroy();
      }

      if (this.wsStore && this.wsStore.destroy) {
        this.wsStore.destroy();
      }

      if (this.aboutStore && this.aboutStore.destroy) {
        this.aboutStore.destroy();
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export default RootStore;
