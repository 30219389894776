import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";

import useStores from "~/core/utils/useStores";
import RepoNode from "./RepoNode";
import { CLS_REPO_FILE } from "~/core/constants/Classes";
import RepoStore from "../../stores/RepoStore";

/**
 * Компонент для дерева репозитория
 *
 * @param {Object} params набор параметров
 * @param {String} params.modeView режим просмотра репозитория 
 * @param {LayoutItem} params.layoutItem панель Layout
 * @param {RepoStore} params.store Хранилище для работы с репозиторием
 * @param {String} params.path путь до ноды дерева, до которой нужно сделать проход, чтобы  развернуть дерево
 * @param {Number} params.focusCodeLine номер линии, куда нужно выставить фокус при открытии файла.
 * Это необходимо при переходе по ссылке из другого инструмента
 * @param {Function} params.onNodeClick callback функция при клике на иконку записи ноды
 *
 * @type {RepoTree}
 * @returns {Component}
 */
const RepoTree = observer(((props) => {
  // const treeRef = useRef(null);
  const {
    modeView,
    store,
    path,
    repoName,
    focusCodeLine,
    onNodeClick
  } = props;
  const { objectStore } = useStores();

  const [pathPending, setPathPending] = useState(path && path.length > 0);

  // Загрузка данных для дерева
  useEffect(async() => {
    // если задан path, то значит нужно загрузить ноды по этому пути,
    // подгрузить данные этих нод и развернуть все ноды по этому пути
    if (path && path.length > 0 && store.rootRepoNode) {
      processPath(path);
    }
  }, [path, store.rootRepoNode]);

  const processPath = async(path) => {
    // Делаем обработку path. 
    setPathPending(true);
    let i = 0;
    let lastNode;
    try {
      while (i < path.length) {
        if (path[i] === "") {
          // значит root
          lastNode = store.rootRepoNode;
        } else {
          // eslint-disable-next-line no-loop-func
          const node = (lastNode && lastNode.childItems || []).find((item) => {
            return item.name === path[i];
          });
          if (node) {
            lastNode = node;
            if (!node.expanded) {
              const b = await loadNodeData(node);
              if (b) {
                node.expand(true);
              }
            }
          }
        }

        i += 1;
      }
      
      if (lastNode) {
        store.setSelectedNode(lastNode);
        if (lastNode.class === CLS_REPO_FILE) {
          // если путь был до файла, то загружаем его содержимое
          onNodeClick && onNodeClick(lastNode, focusCodeLine);
        }
      }
    } finally {
      setPathPending(false);
    }
  };

  const onExpandNode = useCallback(async(node) => {
    if (node.children.length === 0) {
      const b = await loadNodeData(node);
      if (b) {
        node.expand(true);
      }
    } else {
      node.expand(!node.expanded);
    }
  }, []);

  const loadNodeData = async(node) => {
    node.setIsPending(true);
    try {
      const data = await objectStore.fetchRepresentation(
        node.uid,
        node.domain,
        node.version,
        {},
        {
          force: true
        }
      );
      node.update(data);
      return true;
    } catch (ex) {
      store.onError(ex.message);
    } finally {
      node.setIsPending(false);
    }
    return false;
  };

  // Отрисовка ноды дерева
  const renderNode = useCallback((node) => {
    return (
      <RepoNode
        repoName={repoName}
        node={node}
        store={store}
        modeView={modeView}
        onClick={onNodeClick}
      />
    );
  }, [modeView, repoName]);

  return (
    <div className="repo-tree">
      {(pathPending || (!store.rootRepoNode && store.isPending)) && (
        <div className="preloader-wrapper">
          <Components.Preloader size={3} />
        </div>
      )}
      {!pathPending && store.rootRepoNode && (
        <Components.Tree
          root={store.rootRepoNode}
          renderItem={renderNode}
          onExpandNode={onExpandNode}
        />
      )}
    </div>
  );
})
);

RepoTree.propTypes = {
  layoutItem:    PropTypes.object,
  modeView:      PropTypes.string,
  store:         PropTypes.instanceOf(RepoStore),
  path:          PropTypes.arrayOf(String),
  focusCodeLine: PropTypes.number,
  onNodeClick:   PropTypes.func
};

export default RepoTree;
