import { observable, action, computed } from "mobx";

class TreeNode {
  @observable store = null;
  @observable uid = null;
  @observable editing = false;
  @observable expanded = false;

  constructor(uid, store) {
    this.store = store;
    this.uid = uid;
  }

  @action
  setExpanded(expanded = false) {
    this.expanded = expanded;
  }

  @action
  setEditing(editing = false) {
    this.editing = editing;
  }

  @action
  setPending(pending = false) {
    this.item && this.item.setPending(pending);
  }

  @action
  async export({ format }) {
    if (this.isVersion && !this.isPending) {
      this.item.setPending(true);
      const data = await this.store.api.export(format, this.item.parentUid, this.number);
      this.item.update(data);
      this.item.setPending(false);
    }
  }

  @action
  rename(name) {
    if (name) {
      this.name = name;
    }
    this.setEditing(false);
  }

  @action
  revert() {
    this.setEditing(false);
  }

  @action
  async uploadFile(files, position = 0) {
    this.setPending(true);
    const resultUidArray = await this.store.uploadFile(this.uid, position, files);
    this.item && this.item.insertChildIds(resultUidArray, position);
    this.setPending(false);
  }

  /**
   * Импорт файла
   * 
   * @param {Array<Files>} files набор файлов для импорта
   * @param {Number} position номер позиции в коллекции, куда нужно будет потом доабавить ноду с файлом
   * @param {Object} importScheme описание схемы импорта
   * @param {String} importScheme.parser_key название-ключ парсера
   * @param {String} importScheme.uid uid схемы для парсера
   * @param {String} importScheme.name название схемы для парсера
   */
  @action
  async importFile(files, position = 0, importScheme) {
    this.setPending(true);
    const resultUidArray = await this.store.uploadFile(this.uid, position, files);
    const resultImportUidArray = await this.store.importFile(this.uid, position, files, importScheme);
    this.item && this.item.insertChildIds([...(resultUidArray || []), ...(resultImportUidArray || [])], position);
    this.setPending(false);
  }

  @computed
  get item() {
    return this.store.getVersion(this.uid);
  }

  @computed
  get root() {
    return this.store.root;
  }

  @computed
  get number() {
    return this.item && this.item.number;
  }

  @computed
  get fileType() {
    return this.item && this.item.fileType;
  }

  @computed
  get fileId() {
    return this.item && this.item.fileId;
  }

  @computed
  get editable() {
    return (this.item && this.item.editable) || (this.item && this.item.parent && this.item.parent.editable);
  }

  @computed
  get timestamp() {
    return this.item && this.item.timestamp;
  }

  @computed
  get path() {
    return this.item && this.item.path;
  }

  @computed
  get isRoot() {
    return this.root && this.root.uid === this.uid;
  }

  @computed
  get rootLevel() {
    if (this.isRoot) {
      return 0;
    }
    return (this.root && this.root.level + 1) || 0;
  }

  @computed
  get class() {
    return this.item && this.item.class;
  }

  @computed
  get isVersion() {
    return this.item && this.item.isVersion;
  }

  @computed
  get isEditing() {
    return this.editing;
  }

  @computed
  get isExpanded() {
    return this.expanded || this.isRoot;
  }

  @computed
  get isPending() {
    return this.pending || (this.item && this.item.isPending);
  }

  @computed
  get level() {
    return (this.item && this.item.level || 0) - this.rootLevel;
  }

  @computed
  get isLeaf() {
    return this.item && this.item.isLeaf;
  }

  @computed
  get childItems() {
    if (!this.item || !this.item.childIds) {
      return [];
    }
    return this.item.childIds.map(((childUid) => {
      return this.store.getNode(childUid);
    }));
  }

  @computed
  get parent() {
    return (
      this.store.getNode(this.item && this.item.parentUid)
    );
  }

  @action
  clearChilds() {
    this.item.clearChilds();
  }

  @action
  addChild(uid, position) {
    this.item.addChild(uid, position);
  }
}

export default TreeNode;
